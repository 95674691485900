
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import StickerMock from "@/assets/svg/sticker-mock.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";

export default defineComponent({
  name: "Sticker",
  data() {
    return {
      isModalDelete: false,
      stickerId: null,
      stickerData: {},
    };
  },
  components: {
    ArrowBack,
    StickerMock,
    DefaultPopup,
    Edit,
    Delete,
  },
  mounted() {
    this.stickerId = this.$route.query.sticker;
    this.getSticker(this.stickerId);
  },
  methods: {
    getSticker(id) {
      this.$store.dispatch("stickers/getSticker", id).then(
        (res) => {
          this.stickerData = res.data;
        },
        (error) => {
          this.errorsticker = error.response.data.errors;
        }
      );
    },
    openDeletePoup() {
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
    },
    deleteSticker() {
      this.$store.dispatch("stickers/deleteSticker", this.stickerId).then(
        (res) => {
          this.$router.push("/admin/stickers");
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    editSticker(id) {
      this.$router.push("/admin/create-sticker?edit=true&sticker=" + id);
    },
  },
});
