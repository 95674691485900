import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sticker" }
const _hoisted_2 = { class: "sticker__wrap" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "sticker__value" }
const _hoisted_5 = { class: "sticker__value" }
const _hoisted_6 = { class: "sticker__controls" }
const _hoisted_7 = { class: "sticker__popup-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StickerMock = _resolveComponent("StickerMock")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/stickers",
      class: "sticker__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (this.stickerData.image === undefined)
          ? (_openBlock(), _createBlock(_component_StickerMock, {
              key: 0,
              class: "sticker__photo"
            }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "sticker__photo",
              src: this.stickerData.image,
              alt: this.stickerData.title
            }, null, 8, _hoisted_3))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sticker__title" }, "Title", -1)),
      _createElementVNode("div", _hoisted_4, _toDisplayString(this.stickerData.title), 1),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sticker__title" }, "Description", -1)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(this.stickerData.description), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Edit, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editSticker(this.stickerData.id)))
        }),
        _createVNode(_component_Delete, { onClick: _ctx.openDeletePoup }, null, 8, ["onClick"])
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "sticker__popup-title" }, "Are you sure?", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "sticker__popup-button-yes",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteSticker && _ctx.deleteSticker(...args)))
              }, "Yes"),
              _createElementVNode("div", {
                class: "sticker__popup-button-no",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}